import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Article } from "../types"
import { breakpoint, font, getArticleUrl } from "../util"
import { useAttributes, useLatestArticles, useScrollToBottom } from "../hooks"
import { Section } from "./ui";
import { CategoryBar } from "./categoryBar"

type RecentArticlesProps = {
  authorId?: string;
  limit?: number;
  attributes?: any;
}

export const RecentArticles: React.FC<RecentArticlesProps> = props => {
  const { attributes = {}, authorId = "", limit = 9 } = props;
  const { show_nav_bar, selected_category } = attributes;

  const [nextToken, setNextToken] = useState<string>();
  const [items, setItems] = useState([]);

  const { data: newData, loading } = useLatestArticles(limit, nextToken, authorId, "article", selected_category);
  const articles = loading ? items : Array.from(new Set(items.concat(newData.getContents.items)))

  const containerRef = useScrollToBottom(() => {
    if (loading || !newData.getContents.nextToken) return;
    setItems(articles)
    setNextToken(newData.getContents.nextToken);
  });

  return <RecentArticlesSection ref={containerRef} data-testid="recent-articles">
    <RecentArticlesTitle>
      <h3>RECENT ARTICLES & INSIGHTS</h3>
    </RecentArticlesTitle>
    {show_nav_bar === "1" ? <CategoryBar selectedCategoryId={selected_category} /> : null}
    {articles.map(article => <ArticleCard article={article} key={article.id} />)}
    {loading ? "Loading..." : null}
  </RecentArticlesSection>
}

const ArticleCard: React.FC<{ article: Partial<Article> }> = props => {
  const { article } = props;
  const { hero_image_description } = useAttributes(article.attributes) as any;
  return <StyledCard to={getArticleUrl(article.slug)}>
    <ImageContainer>
      <img src={article.thumbnail} alt={hero_image_description} />
    </ImageContainer>
    <LabelContainer>
      <span className="category">{article.category?.name}</span>
      <h4 className="title">{article.title}</h4>
      <span className="subtitle">{article.subtitle}</span>
      <span className="read-more">Read More →</span>
    </LabelContainer>
  </StyledCard>
}

const RecentArticlesSection = styled(Section)`
  margin: 40px 0;
`

const RecentArticlesTitle = styled.div`
margin-bottom: 30px;
${breakpoint.desktop`
  margin-left: 0;
  margin-right: 0;
`}
h3 {
    ${font.m3}
    color: #232628;
  }
  border-bottom: 1px solid #C4C4C4;
`

const StyledCard = styled(Link)`
  padding: 18px 0;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 24px;
  ${breakpoint.mobile`
   grid-template-columns: 32% 65%;
  `}

  text-decoration: none;
  border-bottom: 1px dashed #D9D9D9;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    text-decoration: none;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  img {
    height: auto;
    width: 100%;
    object-fit: cover;
    margin-bottom: 0;
  }
`

const LabelContainer = styled.div`
  .category {
    ${font.c8}
    text-transform: uppercase;
    color: #666666;
    padding-bottom: 8px;
  }
  .title {
    ${font.c4}
    color: #232628;
    margin-bottom: 8px;
  }
  .subtitle {
    ${font.c6}
    color: #232628;
    padding-bottom: 8px;
  }
  .read-more {
    ${font.c6}
    color: #2683FC;
    display: block;
    margin-top: 8px;
  }
`