import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Category } from '../types';
import { font, getCategoryUrl } from '../util';
import { useStaticCategories } from '../hooks/useStaticCategories';

type CategoryBarProps = {
  selectedCategoryId: string;
}

export const CategoryBar: React.FC<CategoryBarProps> = props => {
  const { selectedCategoryId } = props;

  const categories = useStaticCategories();

  return <StyledCategoryBar>
    <CategoryLink key="all" filter="All articles" path="" active={!selectedCategoryId} />
    {categories.map(({ filter, path, id, color }) => <CategoryLink
      key={id}
      filter={filter}
      path={path}
      color={color}
      active={id === selectedCategoryId}
    />)}
  </StyledCategoryBar>
}

type CategoryLinkProps = {
  filter: string;
  path: string;
  active: boolean;
  color?: string;
}
const CategoryLink: React.FC<CategoryLinkProps> = props => {
  const { filter, path, active, color } = props;
  return <StyledLink color={color} to={getCategoryUrl(path)} className={active ? `active` : ""}>
    {filter}
  </StyledLink>
}

const StyledCategoryBar = styled.header`
  display: flex;
  flex-wrap: wrap;
`

const StyledLink = styled(Link)`
  ${font.c5}
  color: ${({ theme }) => theme.primary};
  border-bottom: 2px solid transparent;
  margin-right: 24px;
  margin-bottom: 18px;
  padding-bottom: 4px;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
    border-color: ${({ theme, color }) => color || theme.primary};
  }
  &.active {
    color: #1C7FFF;
    border-color: #1C7FFF;
    font-weight: 600;
  }
`