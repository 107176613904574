import React from 'react'
import styled from 'styled-components'

import linkedin from '../assets/icons/linkedin.svg'
import facebook from '../assets/icons/facebook.svg'
import instagram from '../assets/icons/instagram.svg'
import twitter from '../assets/icons/twitter.svg'
import website from '../assets/icons/website.svg'
import Email from '../assets/icons/email.svg'

import { TypedAttribute } from '../types';
import { prepExternalLink } from '../util'

const icons = { linkedin, facebook, instagram, twitter, website };

type SocialLinksProps = {
  links: TypedAttribute[];
  email?: string;
}

export const SocialLinks: React.FC<SocialLinksProps> = props => {
  const { links, email } = props;
  return <SocialLinksContainer>
    {links.map(({ type, value }) => {
      const Icon = icons[type];
      return Icon ? <a href={prepExternalLink(value)} target="_blank" rel="noopener" key={type}>
        <Icon title={type}/>
      </a> : null
    })}
    {email ? <a href={`mailto: ${email}`}>
      <Email title="email"/>
    </a> : null}
  </SocialLinksContainer>
}

const SocialLinksContainer = styled.div`
  display: flex;
  svg {
    margin: 1.5rem 0.5rem;
  }
`