import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux';

import { Layout } from '../components/layout'
import SEO from '../components/seo'
import { Container, HeroImage, Link } from '../components/ui'
import { openModal } from '../state/actions'
import { TemplateWrapper } from '../components/templateWrapper';
import { getCurrentUrl, STATIC_URL } from '../util';

const url = getCurrentUrl({ slug: '404' })
const metaTags = url ? [{
  property: "og:url",
  content: url,
}] : [];


const NotFoundPage: React.FC = props => {
  const dispatch = useDispatch();
  return (
    <Layout key="404">
      <SEO
        title="404: Not found"
        meta={metaTags}
        canonical={url}
      />
      <TemplateWrapper>
        <HeroImage
          src="/images/404-image.png"
          alt="computer monitor showing error"
          caption="404 Code Error"
          imageStyle={{ margin: "3rem 0" }}
        />
        <StyledContainer>
          <h1>We can’t seem to find the page you are looking for</h1>
          <h2>Here are some helpful links instead.</h2>
          <StyledLink to={STATIC_URL.ARTICLES}>Articles</StyledLink>
        </StyledContainer>
      </TemplateWrapper>
    </Layout>
  )
}

const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 16px;

  // for the modal button:
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`

const StyledContainer = styled(Container)`
  padding-bottom: 3rem;
`

export default NotFoundPage
