import React from 'react'
import styled from 'styled-components'

import { Editor } from '../types'
import { font, getCurrentUrl } from '../util';
import { Layout } from './layout'
import { RecentArticles } from './recentArticles';
import SEO from './seo';
import { SocialLinks } from './socialLinks';
import { Section } from './ui';

type EditorPageProps = {
  editor: Editor;
  pendingMessage?: string;
}

const EditorPage: React.FC<EditorPageProps> = (props) => {
  const { editor, pendingMessage = "Loading..." } = props

  return (
    <Layout>
      {editor ? <>
        <SEO
          title={`${editor.firstName} ${editor.lastName}`}
          canonical={getCurrentUrl({ ...editor, type: "author" })}
        />
        <EditorContainer>
          <EditorAvatar>
            <img src={editor.photo} alt={`${editor.firstName} ${editor.lastName}`} />
          </EditorAvatar>
          <EditorName title="name">{editor.firstName} {editor.lastName}</EditorName>
          <EditorBio title="bio" dangerouslySetInnerHTML={{ __html: editor.bio }} />
          <SocialLinks links={editor.socialLinks} email={editor.email} />
        </EditorContainer>
        <RecentArticles authorId={editor.id} />
      </> : pendingMessage}
    </Layout>
  )
}

const EditorContainer = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 52px;
  padding-bottom: 28px;
  background: linear-gradient(94.22deg, #F4A790 0%, #F4A790 100%);
`

const EditorAvatar = styled.figure`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    min-height: auto;
    object-fit: cover;
  }
`

const EditorName = styled.h1`
  ${font.m1}
  text-align: center;
`

const EditorBio = styled.div`
  p {
    ${font.m5}
  }
  text-align: center;
`

export default EditorPage
