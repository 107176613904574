import React from 'react'

import EditorPage from './editorPage'
import NotFoundPage from '../pages/404'
import { useEditorPreview } from '../hooks/useEditorPreview';

type EditorPreviewPageProps = {
  params: {
    "*": string;
  }
}

const EditorPreviewPage: React.FC<EditorPreviewPageProps> = (props) => {
  const { params } = props
  const { loading, error, data } = useEditorPreview(params['*'])

  if (!data && !loading) return <NotFoundPage />
  return <EditorPage editor={data && data.getEditorPreview} pendingMessage={error && error.message} />
}

export default EditorPreviewPage
